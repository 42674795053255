var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' ? _c('div', {
    staticClass: "flex flex-wrap p-2"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('chart-revenus', {
    attrs: {
      "chartData": _vm.dataExpFees,
      "chartData1": _vm.dataExp,
      "Labels": _vm.labelsExp,
      "label": _vm.labelExp,
      "title": _vm.$t('expeditions_analytic'),
      "type": "line",
      "id": "expeditions",
      "sellers": _vm.sellers,
      "userType": _vm.currentUser.type,
      "timeserch": _vm.timeserchExp,
      "timeserchseller": _vm.timeserchsellerExp,
      "action": "expeditions",
      "labelstring": _vm.labelStringExp
    },
    on: {
      "ChangeTypeDateRevenu": _vm.ChangeTypeDateRevenu,
      "ChangeSeller": _vm.ChangeSellerExp
    }
  })], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }